<template>
  <div class="leet-news-page">
    <div class="datalist" v-if="list.length">
      <a :href="`/news/detail/${item.id}`" class="card" v-for="item in list" :key="item.id">
        <div class="card-img">
          <img :src="item.thumbnail" />
        </div>
        <div class="card-body">
          <div class="date">{{ item.date | formatdate }}</div>
          <div class="title">{{ item.title?.rendered }}</div>
          <div class="summary" v-html="item.excerpt?.rendered"></div>
        </div>
      </a>
    </div>
    <el-skeleton style="width: 100%" :loading="loading" animated>
      <template slot="template">
        <div style="display: flex; flex-direction: column; padding: 10px" v-for="index in 5" :key="index">
          <div style="width: 100%">
            <el-skeleton-item variant="image" style="width: 100%; height: 210px" />
          </div>
          <el-skeleton-item variant="text" style="width: 30%; margin: 10px 0" />
          <div>
            <el-skeleton-item variant="h3" style="width: 100%; margin-bottom: 0px" />
            <el-skeleton-item variant="text" style="width: 100%" />
          </div>
        </div>
      </template>
    </el-skeleton>
    <div class="page-body-pagination" v-if="list.length">
      <el-pagination background :current-page="page" :page-size="per_page" layout="prev, pager, next" :total="total" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getNewsList } from "@/api/news";
const dayjs = require("dayjs");
export default {
  name: "leet-news-page",
  components: {},
  data() {
    return {
      list: [],
      per_page: 6,
      page: 1,
      total: 0,
      totalPages: 0,
      loading: false,
    };
  },
  mounted() {
    this.initData();
  },
  filters: {
    formatdate(datetime) {
      return dayjs(datetime).format("YYYY-MM-DD");
    },
  },
  created() {},
  methods: {
    initData() {
      this.page = 1;
      this.total = 0;
      this.totalPages = 0;
      this.getList();
    },
    getList() {
      this.list = [];
      this.loading = true;
      getNewsList(this.per_page, this.page).then((response) => {
        this.loading = false;
        const totalItems = response.headers.get("X-WP-Total"); // 总条数
        const totalPages = response.headers.get("X-WP-TotalPages"); // 总页数
        this.total = Number(totalItems);
        this.totalPages = Number(totalPages);
        response.data.forEach((post) => {
          if (post._embedded && post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0] && post._embedded["wp:featuredmedia"][0].source_url) {
            const featuredMedia = post._embedded["wp:featuredmedia"][0];
            const fullImage = featuredMedia.source_url.replace(/http:\/\/39\.105\.101\.79:8080/g, "/wpimg");
            const thumbnail = featuredMedia.media_details.sizes.medium?.source_url.replace(/http:\/\/39\.105\.101\.79:8080/g, "/wpimg") || fullImage;
            post.thumbnail = thumbnail;
          }
        });
        this.list = response.data;
      });
    },
    handleCurrentChange(valpage) {
      this.page = valpage;
      this.getList();
    },
  },
  destroyed() {},
  watch: {
    $route() {
      this.initData();
    },
  },
};
</script>
<style scoped lang="less">
.leet-news-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .datalist {
    a {
      color: initial;
      text-decoration: none;
    }
    .card {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #f0f0f0;
      padding: 10px 10px;
      margin: 0;
      box-sizing: border-box;
      .card-img {
        width: 100%;
        flex: 0 0 210px;
        position: relative;
        height: 210px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }
      .card-body {
        position: relative;
        padding: 10px 0;
        .date {
          opacity: 0.5;
          margin-bottom: 7px;
          font-size: 14px;
        }
        .title {
          font-size: 16px;
          line-height: 21px;
        }
        .summary {
          font-size: 14px;
          line-height: 28px;
          overflow: hidden;
          opacity: 0.7;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  .page-body-pagination {
    text-align: center;
    padding: 40px 0;
    .el-pagination {
      .is-background .el-pager li:not(.disabled).active {
        background-color: #243953 !important;
        color: #fff !important;
      }
    }
  }
}
</style>
